#overviewCalendar .fc .fc-today-button.fc-button-primary {
  background-color: #17355D;
  border-color: #17355D;
}

#overviewCalendar .fc .fc-today-button.fc-button-primary:hover {
  background-color: #02214A;
  border-color: #02214A;
}

#overviewCalendar .fc td.fc-timeline-slot.fc-day-sat {
  background-color: gray;
  opacity: 0.5;
}

#overviewCalendar .fc td.fc-timeline-slot.fc-day-sun {
  background-color: gray;
  opacity: 0.5;
}

#overviewCalendar .fc .fc-next-button.fc-button-primary {
  background-color: #17355D;
  border-color: #17355D;
}

#overviewCalendar .fc .fc-prev-button.fc-button-primary {
  background-color: #17355D;
  border-color: #17355D;
}

#overviewCalendar .fc .fc-next-button.fc-button-primary:hover {
  background-color: #02214A;
  border-color: #02214A;
}

#overviewCalendar .fc .fc-prev-button.fc-button-primary:hover {
  background-color: #02214A;
  border-color: #02214A;
}

#overviewCalendar .fc .fc-resourceTimelineWeek-button.fc-button-primary {
  background-color: #17355D;
  border-color: #17355D;
  margin-right: 5;
}

#overviewCalendar .fc .fc-resourceTimelineMonth-button.fc-button-primary {
  background-color: #17355D;
  border-color: #17355D;
}

#overviewCalendar .fc .fc-resourceTimelineWeek-button.fc-button-primary:hover {
  background-color: #02214A;
  border-color: #02214A;
}

#overviewCalendar .fc .fc-resourceTimelineMonth-button.fc-button-primary:hover {
  background-color: #02214A;
  border-color: #02214A;
}

#overviewCalendar .custom-fullcalendar {
  font-family: inherit;
}