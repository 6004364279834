.tooltipWaiting {
    background-color: yellow;
    color: black;
  }
  
  .tooltipApproved {
    background-color: green;
    color: white;
  }
  
  .tooltipCancelled {
    background-color: red;
    color: white;
  }
  