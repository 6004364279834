.tableContainer {
  width: 100%;
  padding: 0px 10px 10px 10px;
  box-sizing: border-box;
  overflow-x: auto;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tableHeader,
.tableBody {
  display: grid;
}

.tableHeader {
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
}

.tableBody {}

.tableCell {
  height: 40px;
  border: 1px solid #ccc;
}

.tableCellHeader {
  height: 50px !important;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.tableCellHeaderBtn {
  border-top: 1px solid transparent !important;
  border-left: none !important;
}

.employeeName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  /* Align names to the left */
  background: white;
  position: sticky;
  left: 0;
  z-index: 1;
  border-left: none;
  /* Remove left border for names */
}

.highlightCell {
  background-color: #ffe0b2;
}

.highlightUK,
.highlightRS {
  background-color: #e0f7fa;
  cursor: pointer;
}

/* Custom styles for weekends */
/* Custom styles for weekends */
.saturday {
  background-color: #FFE5B4 !important;
  /* Orange for Saturday */
}

.sunday {
  background-color: #FFE5B4 !important;
  /* Orange for Sunday */
}

/* Custom styles for today, future, and past days */
.today {
  color: #17355D !important;
  /* Yellow for today */
}

.futureDay {
  color: #0288d1 !important;
  /* Light blue for future days */
}

.pastDay {
  color: #c2185b !important;
  /* Light pink for past days */
}